import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import { Radio } from "antd";
import API, {COMMON, ADMIN_POKER, ADMIN_HILO, ADMIN_KENO, ADMIN_MINES } from "../../network/api";
import "moment-timezone";
import ModalCommon from "../../components/Modal";
import { TEXT_DEF } from "../../constant/text";
import { getBetType, getChildBetType, getCategory } from "../../components/until";
import PokerSetting from "./poker";
import HiloSetting from "./hilo";
import KenoSetting from "./keno";
import MinesSetting from "./mines";

const SettingOriginals = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");

  const [indexTab, setIndexTab] = useState(0);
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [qaList, setQaList] = useState();
  const [isReloadQa, setIsReloadQa] = useState(false);
  const [gameList, setGameList] = useState();
  const [isReloadGame, setIsReloadGame] = useState(false);
  const [dataSys, setDataSys] = useState();
  const [pokerSys, setPokerSys] = useState();
  const [isReloadPoker, setIsReloadPoker] = useState(false);
  const [hiloSys, setHiloSys] = useState();
  const [isReloadHilo, setIsReloadHilo] = useState(false);
  const [kenoSys, setKenoSys] = useState();
  const [isReloadKeno, setIsReloadKeno] = useState(false);
  const [minesSys, setMinesSys] = useState();
  const [isReloadMines, setIsReloadMines] = useState(false);

  const [rule, setRule] = useState();
  const [percent, setPercent] = useState();
  const [percentChange, setPercentChange] = useState();
  const [course1, setCourse1] = useState();
  const [course2, setCourse2] = useState();
  const [course3, setCourse3] = useState();

  const [key, setKey] = useState();
  const [idField, setIdField] = useState();

  useEffect(() => {
    getdataSystem();
  }, [isReloadQa, isReloadGame, isReloadPoker, isReloadHilo, isReloadKeno, isReloadMines, indexTab]);

  const getdataSystem = async () => {
    if (indexTab === 0) {
      // video poker
      try {
        const responseGame = await API.get(`${ADMIN_POKER}/getConfig`);
        const resultGame = responseGame.data?.data?.result;
        if (resultGame) {
          setPokerSys(resultGame);
        }
      } catch (err) {
        console.log(err);
      }
    } else if (indexTab === 1) {
      // hilo
      try {
        const responseGame = await API.get(`${ADMIN_HILO}/getConfig`);
        const resultGame = responseGame.data?.data?.result;
        if (resultGame) {
          setHiloSys(resultGame);
        }
      } catch (err) {
        console.log(err);
      }
    } else if (indexTab === 2) {
      try {
        const responseGame = await API.get(`${ADMIN_KENO}/getConfig`);
        const resultGame = responseGame.data?.data?.result;
        if (resultGame) {
          setKenoSys(resultGame);
        }
      } catch (error) {
        console.log(error);
      }
    } else if (indexTab === 3) {
      try {
        const responseGame = await API.get(`${ADMIN_MINES}/getConfig`);
        const resultGame = responseGame.data?.data?.result;
        if (resultGame) {
          setMinesSys(resultGame);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleChangeRule = (e) => {
    const { name, value } = e.target;
    setRule((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleShowModal = (id, key) => {
    setIsShowModalConfirm(true);
    setIdField(id);
    setKey(key);
  };

  const handleUpdate = () => {
    switch (key) {
      case "rule":
        return handleSubmit();
      case "percent":
      case "percentChange":
        return handleUpdatePercent();
      case "course1":
        return handleUpdateCourse1();
      case "course2":
        return handleUpdateCourse2();
      case "course3":
        return handleUpdateCourse3();
    }
  };

  const handleSubmit = async () => {
    try {
      setIsShowModalConfirm(false);
      setIsShowModalSuccess(true);
      const body = {
        value: rule.value,
        value1: rule.value1,
      };
      await API.patch(`${COMMON}/${rule.id}`, body);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangePercent = useCallback(
    (e, id) => {
      setPercent(percent.map((el) => (el.id === id ? { ...el, value: e } : el)));
      setPercentChange(percentChange.map((item) => (item.id === id ? { ...item, value: e } : item)));
    },
    [percent, percentChange]
  );

  const handleUpdatePercent = async () => {
    try {
      setIsShowModalConfirm(false);
      setIsShowModalSuccess(true);

      const body = {
        value: key === "percentChange" ? percentChange.find((el) => el.id === idField).value.toString() : percent.find((el) => el.id === idField).value.toString(),
      };
      await API.patch(`${COMMON}/${idField}`, body);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateCourse1 = async () => {
    try {
      setIsShowModalConfirm(false);
      setIsShowModalSuccess(true);
      const body = {
        value: course1.value === true ? "1" : "0",
      };
      await API.patch(`${COMMON}/${idField}`, body);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateCourse2 = async () => {
    try {
      setIsShowModalConfirm(false);
      setIsShowModalSuccess(true);
      const body = {
        value: course2.value === true ? "1" : "0",
      };
      await API.patch(`${COMMON}/${idField}`, body);
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdateCourse3 = async () => {
    try {
      setIsShowModalConfirm(false);
      setIsShowModalSuccess(true);
      const body = {
        value: course3.value === true ? "1" : "0",
      };
      await API.patch(`${COMMON}/${idField}`, body);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="col-12">
      <div
        className="col-12"
        style={{
          borderBottom: "1px solid #CCC",
          paddingBottom: "2rem",
          marginBottom: "2rem",
        }}
      >
        <Radio.Group value={indexTab} onChange={(e) => setIndexTab(e.target.value)}>
          <Radio.Button auth={auth} roleArr={roleArr} value={0}>
            Video Poker
          </Radio.Button>
          <Radio.Button auth={auth} roleArr={roleArr} value={1}>
            Hilo
          </Radio.Button>
          <Radio.Button auth={auth} roleArr={roleArr} value={2}>
            Keno
          </Radio.Button>
          <Radio.Button auth={auth} roleArr={roleArr} value={3}>
            Mines
          </Radio.Button>
        </Radio.Group>
      </div>
      {indexTab === 0 ? (
        <PokerSetting auth={auth} roleArr={roleArr} data={pokerSys} reload={() => setIsReloadPoker(!isReloadPoker)} />
      ) : indexTab === 1 ? (
        <HiloSetting auth={auth} roleArr={roleArr} data={hiloSys} reload={() => setIsReloadHilo(!isReloadHilo)} />
      ) : indexTab === 2 ? (
        <KenoSetting auth={auth} roleArr={roleArr} data={kenoSys} reload={() => setIsReloadKeno(!isReloadKeno)} />
      ) : indexTab === 3 ? (
        <MinesSetting auth={auth} roleArr={roleArr} data={minesSys} reload={() => setIsReloadMines(!isReloadMines)} />
      ) : null}
      {isShowModalConfirm && (
        <ModalCommon isShow={isShowModalConfirm} message={TEXT_DEF.text0062} txtBtnAcc={TEXT_DEF.text0008} textBtnClose={TEXT_DEF.text0023} actionNext={handleUpdate} close={() => setIsShowModalConfirm(false)} />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text0063}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </div>
  );
};

export default SettingOriginals;
