export const getGame = (type, seconds) => {
    let text = '';
    if (type.indexOf("xs") == -1) {
      switch (type) {
        case 'hilo': text = 'Hilo'; break;
        case 'video-poker': text = 'Video Poker'; break;
        case 'keno': text = 'Keno'; break;
        case 'mines': text = 'Mines'; break;
        default: break;
      }
    } else {
      switch (type) {
        case 'xsspl': text = 'Super Rich Lottery'; break;
        case 'xsmb': text = 'Miền Bắc'; break;
        case 'xsmt': text = 'Miền Trung'; break;
        case 'xsmn': text = 'Miền Nam'; break;
        default: break;
      }
      text = text + ` ${seconds === 0 ? '' : Number(seconds) === 1 ? 'Tức Thì' : seconds + ' giây' } `
    }
  return text
}

export const getCategory = (key) => {
    if (!key) return;
    
    const kyCv = key.substring(0, 2);
    let text = '';
    switch (kyCv) {
      case 'xs': text = 'Xổ số nhanh'; break;
      default: text = ''; break;
    }
    return text
}

export const extractNumbers = (text) => {
    if (!text) return null
    const numbers = text.match(/\d+/g);

    if (numbers && numbers.length > 0) {
        return parseInt(numbers[0], 10);
    } else {
        return null;
    }
}

export const getBetType = (type, seconds) => {
    let text = '';
    
    switch (type) {
      case 'Lo_Truot': text = 'Lô trượt'; break;
      case 'Bao_Lo': text = 'Bao lô'; break;
      case 'Lo_Xien': text = 'Lô xiên'; break;
      case 'Danh_De': text = 'Đánh đề'; break;
      case 'Dau_Duoi': text = 'Đầu đuôi'; break;
      case '3_Cang': text = '3 càng'; break;
      case '4_Cang': text = '4 càng'; break;
      case 'special': text = 'Trò chơi thú vị'; break;

      case 'poker': text = 'Video Poker'; break;
      case 'hilo': text = 'Hilo'; break;
      case 'keno': text = 'Keno'; break;
      case 'mines': text = 'Mines'; break;
      default: break;
    }

    return text
}

export const getChildBetType = (type, seconds) => {
    let text = '';
    
    switch (type) {
      case 'Lo_2_So': text = 'Lô 2 số'; break;
      case 'Lo_2_So_Dau': text = 'Lô 2 số đầu'; break;
      case 'Lo_2_So_1k': text = 'Lô 2 số 1K'; break;
      case 'Lo_3_So': text = 'Lô 3 số'; break;
      case 'Lo_4_So': text = 'Lô 4 số'; break;
      case 'Xien_2': text = 'Xiên 2'; break;
      case 'Xien_3': text = 'Xiên 3'; break;
      case 'Xien_4': text = 'Xiên 4'; break;
      case 'De_Giai_8': text = 'Đề giải 8'; break;
      case 'De_Dac_Biet': text = 'Đề đặc biệt'; break;
      case 'De_Dau_Duoi': text = 'Đề đầu đuôi'; break;
      case 'Dau_Duoi_Dau': text = 'Đầu đuôi đầu'; break;
      case 'Dau_Duoi_Duoi': text = 'Đầu đuôi đuôi'; break;
      case 'C3_Cang_Dac_Biet': text = '3 càng đặc biệt'; break;
      case 'C3_Cang_Giai_7': text = '3 càng giải bảy'; break;
      case 'C3_Cang_Dau_Duoi': text = '3 càng đầu đuôi'; break;
      case 'C4_Cang_Dac_Biet': text = '4 càng đặc biệt'; break;
      case 'Xien_Truot_4': text = 'Xiên trượt 4'; break;
      case 'Xien_Truot_8': text = 'Xiên trượt 8'; break;
      case 'Xien_Truot_10': text = 'Xiên trượt 10'; break;
      case '2_So_DB': text = 'Trò chơi thú vị'; break;
      default: break;
    }

    return text
}

export const getTxtRewardPoker = (key) => {
  let text = '';
  
  switch (key) {
    case '1Doi': text = '1 Đôi'; break;
    case '2Doi': text = '2 Đôi'; break;
    case 'XamCo': text = 'Xám Cô'; break;
    case 'Sanh': text = 'Sảnh'; break;
    case 'Thung': text = 'Thùng'; break;
    case 'CuLu': text = 'Cù Lũ'; break;
    case 'TuQuy': text = 'Tứ Quý'; break;
    case 'ThungPhaSanh': text = 'Thùng Phá Sảnh'; break;
    default: break;
  }

  return text
}

export const getTxtRewardHilo = (data) => {
  if (!data) return false;

  let text = 'Bắt đầu, ';
  const arr = data?.split(", ");
  arr.map(item => {
    if (item == -1) {
      text += 'Thấp hơn, '
    } else if (item == 0) {
      text += 'Bỏ qua, '
    } else if (item == 1) {
      text += 'Cao hơn, '
    } else if (item == 2) {
      text += 'Bằng nhau, '
    }
  })

  return text.substring(0, text.length - 2)
}

export const formatGameCategory = (key) => {
  if (!key) return;
  let text = '';
  switch (key) {
    case 'xoso': text = 'Xổ số nhanh'; break;
    case 'xstt': text = 'Xổ số truyền thống'; break;
    case 'casino': text = 'Casino'; break;
    default: text = ''; break;
  }
  return text
}

export const getOpCategoryByGameTxt = () => {
  return [
    {
      id: 0,
      value: 'XoSo',
      label: 'Xổ Số'
    },
    {
      id: 1,
      value: 'Casino',
      label: 'Game Việt'
    }
  ]
}

export const getOpBetTypeByGameTxt = (cate = '') => {
  const lottery = [
    {
      id: 0,
      value: 'Bao_Lo',
      label: 'Bao Lô'
    },
    {
      id: 1,
      value: 'Lo_Xien',
      label: 'Lô Xiên'
    },
    {
      id: 2,
      value: 'Danh_De',
      label: 'Đánh Đề'
    },
    {
      id: 3,
      value: 'Dau_Duoi',
      label: 'Đầu Đuôi'
    },
    {
      id: 4,
      value: '3_Cang',
      label: '3 Càng'
    },
    {
      id: 5,
      value: '4_Cang',
      label: '4 Càng'
    },
    {
      id: 6,
      value: 'Lo_Truot',
      label: 'Lô Trượt'
    },
    {
      id: 7,
      value: 'special',
      label: 'Trò chơi thú vị'
    }
  ]
  const orginals = [
    {
      id: 8,
      value: 'poker',
      label: 'Video Poker'
    },
    {
      id: 9,
      value: 'hilo',
      label: 'Hilo'
    },
    {
      id: 10,
      value: 'keno',
      label: 'Keno'
    },
    {
      id: 11,
      value: 'mines',
      label: 'Mines'
    }
  ]

  if(cate == 'Casino') {
    return orginals;
  } 
  if(cate == 'XoSo'){
    return lottery;
  }
  
  return [...lottery, ...orginals]

}
