import React, { useEffect, useState } from "react";
import API, { QA } from "../../network/api";
import { Form, Input, Button, Select } from "antd";
import { TEXT_DEF } from "../../constant/text";
import { fetchData } from "./action";
import { ConfirmModal } from "./mainModal";

const CreateQA = ({ handleCancelModal, setIsReloadQa }) => {
  const [frmCreateQA] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mainTopics, setMainTopics] = useState([]);
  const [subTopics, setSubTopics] = useState([]);
  const [selectedMainTopicId, setSelectedMainTopicId] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  useEffect(() => {
    getAllMainTopics();
  }, []);

  useEffect(() => {
    const getMainTopicByParentId = async () => {
      if (!selectedMainTopicId) {
        return;
      }

      const params = new URLSearchParams({
        parentId: selectedMainTopicId,
      });

      const response = await API.get(`${QA}/all?${params.toString()}&level=2`);
      const result = response?.data?.data?.result?.qa;
      setSubTopics(result);
    };

    getMainTopicByParentId();
  }, [selectedMainTopicId]);

  const getAllMainTopics = async () => {
    const listMainTopic = await fetchData(`${QA}/all?level=1`, (item) => item.parent === null);
    setMainTopics(listMainTopic);
  };

  const handleChangeMainTopics = (value) => {
    setSelectedMainTopicId(value);
  };

  const onCreateNewQA = async (payload) => {
    setIsSubmitting(true);

    try {
      await API.post(`${QA}/create`, payload).then((response) => {
        const statusCode = response?.data?.data?.code;
        if (statusCode === 20001) {
          setIsReloadQa(true);
          handleCancelModal();
        }
      });
    } catch (error) {
      setIsSubmitting(false);
      console.log("Error: ", error);
    }
  };

  const handleConfirm = () => {
    onCreateNewQA(formValues);
    alert(TEXT_DEF.text0185);
  };

  const handleSubmit = () => {
    frmCreateQA
      .validateFields()
      .then((values) => {
        const { question, answer, parentId } = values;
        const filteredValues = { question, answer, parentId };

        setFormValues(filteredValues);
        setIsConfirmModalOpen(true);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  const renderCreateQA = () => {
    return (
      <>
        {!isConfirmModalOpen ? (
          <Form form={frmCreateQA} autoComplete="off" style={{ padding: "2rem" }} onFinish={handleSubmit}>
            <div className="form-group col-12 flex">
              <label htmlFor="parentName" className="col-3">
                {TEXT_DEF.text0175} <span style={{ color: "red" }}>*</span>
              </label>
              <Form.Item
                name="parentName"
                style={{ marginRight: 0, width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập thông tin",
                  },
                ]}
              >
                <Select
                  placeholder={TEXT_DEF.text0180}
                  onChange={handleChangeMainTopics}
                  options={mainTopics.map((option) => ({
                    value: option?.id,
                    label: option?.name,
                  }))}
                />
              </Form.Item>
            </div>
            <div className="form-group col-12 flex">
              <label htmlFor="parentId" className="col-3">
                {TEXT_DEF.text0176} <span style={{ color: "red" }}>*</span>
              </label>
              <Form.Item
                name="parentId"
                style={{ marginRight: 0, width: "100%" }}
                disabled={!selectedMainTopicId}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập thông tin",
                  },
                ]}
              >
                <Select
                  placeholder={TEXT_DEF.text0180}
                  options={subTopics.map((option) => ({
                    value: option.id,
                    label: option.name,
                  }))}
                />
              </Form.Item>
            </div>
            <div className="form-group col-12 flex">
              <label htmlFor="question" className="col-3">
                {TEXT_DEF.text0181} <span style={{ color: "red" }}>*</span>
              </label>
              <Form.Item
                name="question"
                style={{ marginRight: 0, width: "100%" }}
                disabled={!selectedMainTopicId}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập thông tin",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="form-group col-12 flex">
              <label htmlFor="answer" className="col-3">
                {TEXT_DEF.text0182} <span style={{ color: "red" }}>*</span>
              </label>
              <Form.Item
                name="answer"
                style={{ marginRight: 0, width: "100%" }}
                disabled={!selectedMainTopicId}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập thông tin",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-12 flex justify-center gap-10">
              <Form.Item shouldUpdate>
                {() => (
                  <Button type="primary" htmlType="submit" disabled={isSubmitting || !frmCreateQA.isFieldsTouched(true) || !!frmCreateQA.getFieldsError().filter(({ errors }) => errors.length).length}>
                    {TEXT_DEF.text0030}
                  </Button>
                )}
              </Form.Item>
              <Form.Item>
                <Button type="default" onClick={handleCancelModal}>
                  {TEXT_DEF.text0007}
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : (
          <ConfirmModal handleConfirm={handleConfirm} handleCancelModal={handleCancelModal} text={TEXT_DEF.text0190} />
        )}
      </>
    );
  };
  return <>{renderCreateQA()}</>;
};

export default CreateQA;
