import React, { useState } from "react";
import { TEXT_DEF } from "../../../constant/text";
import { Button, InputNumber, Switch } from "antd";
import ModalCommon from "../../../components/Modal";
import API, { ADMIN_MINES } from "../../../network/api";
import { RIGHTS } from "../../../constant/rights";
import { hasRight } from "../../../components/until/right";

const MinesSetting = ({
  data,
  auth,
  roleArr,
  reload
}) => {
  const [isMaintenance, setIsMaintenance] = useState()
  const [valueIp1, setValueIp1] = useState();
  const [valueIp2, setValueIp2] = useState();
  const [valueIp3, setValueIp3] = useState();
  const [valueIp4, setValueIp4] = useState();
  const [valueIp5, setValueIp5] = useState();
  const [valueIp6, setValueIp6] = useState();
  const [valueIp7, setValueIp7] = useState();
  const [valueIp32, setValueIp32] = useState();

  const [valueIp8, setValueIp8] = useState();
  const [valueIp9, setValueIp9] = useState();
  const [valueIp10, setValueIp10] = useState();
  const [valueIp11, setValueIp11] = useState();
  const [valueIp12, setValueIp12] = useState();
  const [valueIp13, setValueIp13] = useState();
  const [valueIp14, setValueIp14] = useState();
  const [valueIp15, setValueIp15] = useState();
  const [valueIp16, setValueIp16] = useState();
  const [valueIp17, setValueIp17] = useState();
  const [valueIp18, setValueIp18] = useState();
  const [valueIp19, setValueIp19] = useState();
  const [valueIp20, setValueIp20] = useState();
  const [valueIp21, setValueIp21] = useState();
  const [valueIp22, setValueIp22] = useState();
  const [valueIp23, setValueIp23] = useState();
  const [valueIp24, setValueIp24] = useState();
  const [valueIp25, setValueIp25] = useState();
  const [valueIp26, setValueIp26] = useState();
  const [valueIp27, setValueIp27] = useState();
  const [valueIp28, setValueIp28] = useState();
  const [valueIp29, setValueIp29] = useState();
  const [valueIp30, setValueIp30] = useState();
  const [valueIp31, setValueIp31] = useState();

  const [dataUp, setDataUp] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);

  const showConfirm = (key) => {
    if(
      valueIp1 <= 0 || valueIp2 <= 0 || valueIp3 <= 4 || valueIp5 <= 0 || valueIp6<= 0 || valueIp7 <= 0 || valueIp8 <= 0 ||
      valueIp9 <= 0 || valueIp10 <= 0 || valueIp11 <= 4 || valueIp12 <= 0 || valueIp13<= 0 || valueIp14 <= 0 || valueIp15 <= 0 ||
      valueIp16 <= 0 || valueIp17 <= 0 || valueIp18 <= 4 || valueIp19 <= 0 || valueIp20<= 0 || valueIp21 <= 0 || valueIp22 <= 0 ||
      valueIp23 <= 0 || valueIp24 <= 0 || valueIp25 <= 4 || valueIp26 <= 0 || valueIp27 <= 0 || valueIp28 <= 0 || valueIp29 <= 0 ||
      valueIp30 <= 0 || valueIp31 <= 0
    ) {
      alert('Giá trị không được nhỏ hơn 0');
      return
    }

    let params;
    const id = data?.find(x => x.key == key)?.id;
    switch (key) {
      case "isMaintenance":
        params = {
          key: "isMaintenance",
          value: isMaintenance.toString()
        }
        break;
      case "firstLose":
        params = {
          key: "firstLose",
          value: valueIp1.toString()
        }
        break;
       case "ratesWin":
        params = {
          key: "ratesWin",
          value: valueIp2.toString()
        }
        break;
      case "ratioRev":
        params = {
          key: "ratioRev",
          value: valueIp3.toString()
        }
        break;
      case "ratioBonus":
        params = {
          key: "ratioBonus",
          value: valueIp4.toString()
        }
        break;
      case "fees":
        params = {
          key: "fees",
          value: valueIp5.toString()
        }
        break;
      case "bias":
        params = {
          key: "bias",
          value: valueIp6.toString()
        }
        break;
      case "ratio_per_difficult":
        params = {
          key: "ratio_per_difficult",
          value: valueIp7.toString()
        }
        break;
      case "1_mines":
        params = {
          key: "1_mines",
          value: valueIp8.toString()
        }
        break;
      case "2_mines":
        params = {
          key: "2_mines",
          value: valueIp9.toString()
        }
        break;
      case "3_mines":
        params = {
          key: "3_mines",
          value: valueIp10.toString()
        }
        break;
      case "4_mines":
        params = {
          key: "4_mines",
          value: valueIp11.toString()
        }
        break;
      case "5_mines":
        params = {
          key: "5_mines",
          value: valueIp12.toString()
        }
      break;
      case "6_mines":
        params = {
          key: "6_mines",
          value: valueIp13.toString()
        }
        break;
      case "7_mines":
        params = {
          key: "7_mines",
          value: valueIp14.toString()
        }
        break;
      case "8_mines":
        params = {
          key: "8_mines",
          value: valueIp15.toString()
        }
        break;
      case "9_mines":
        params = {
          key: "9_mines",
          value: valueIp16.toString()
        }
        break;
      case "10_mines":
        params = {
          key: "10_mines",
          value: valueIp17.toString()
        }
        break;
      case "11_mines":
        params = {
          key: "11_mines",
          value: valueIp18.toString()
        }
        break;
      case "12_mines":
        params = {
          key: "12_mines",
          value: valueIp19.toString()
        }
        break;
      case "13_mines":
        params = {
          key: "13_mines",
          value: valueIp20.toString()
        }
        break;
      case "14_mines":
        params = {
          key: "14_mines",
          value: valueIp21.toString()
        }
        break;
      case "15_mines":
        params = {
          key: "15_mines",
          value: valueIp22.toString()
        }
        break;
      case "16_mines":
        params = {
          key: "16_mines",
          value: valueIp23.toString()
        }
        break;
      case "17_mines":
        params = {
          key: "17_mines",
          value: valueIp24.toString()
        }
        break;
      case "18_mines":
        params = {
          key: "18_mines",
          value: valueIp25.toString()
        }
        break;
      case "19_mines":
        params = {
          key: "19_mines",
          value: valueIp26.toString()
        }
        break;
      case "20_mines":
        params = {
          key: "20_mines",
          value: valueIp27.toString()
        }
        break;
      case "21_mines":
        params = {
          key: "21_mines",
          value: valueIp28.toString()
        }
        break;
      case "22_mines":
        params = {
          key: "22_mines",
          value: valueIp29.toString()
        }
        break;
      case "23_mines":
        params = {
          key: "23_mines",
          value: valueIp30.toString()
        }
        break;
      case "24_mines":
        params = {
          key: "24_mines",
          value: valueIp31.toString()
        }
        break;
      case "num_of_mine_df":
        params = {
          key: "num_of_mine_df",
          value: valueIp32.toString()
        }
        break;
      default: break;
    }
    const dataReq = {
      id: id,
      params: params
    }
    setDataUp(dataReq);
    setIsShowModalConfirm(true)
  }

  const handleChangeSwitch = (e) => {
    setIsMaintenance(e);
  };

  const onRequest = async () => {
    // console.log(rateMinWin)
    if (!dataUp) return;
    await API.patch(`${ADMIN_MINES}/updateConfig/${dataUp?.id}`, dataUp?.params)
      .then(response => {
        const result = response.data?.data;
        if (result?.code === 20004) {
          reload();
          setIsShowModalConfirm(false);
          setIsShowModalSuccess(true);
        }
      }).catch((error) => {
        console.log(error);
      })
  }

  const renderIp = (label, defaultValue, onChangeIp, onUpdate, isHilo, onChangeIp2) => {
    let dataHilo;
    if (isHilo) {
      const dataArr = defaultValue?.split("_");
      dataHilo = {
        value1: Number(dataArr?.[0]),
        value2: Number(dataArr?.[1])
      }
    }
    return (
      <div className="col-12 flex">
        <div className="col-3 text-left">{label}</div>
        <div className="col-6">
          {isHilo ?
            <div className="col-12 flex" style={{ alignItems: 'center' }}>
              <div className="col-2 text-left">Cao: </div>
              <div className="col-3">
                <InputNumber
                  style={{ width: '90%' }}
                  // addonAfter="%" 
                  // min={10} max={100} 
                  min={0}
                  onChange={onChangeIp}
                  defaultValue={dataHilo?.value1}
                />
              </div>
              <div className="col-2 text-left ml-6">Thấp: </div>
              <div className="col-3">
                <InputNumber
                  style={{ width: '90%' }}
                  // addonAfter="%" 
                  // min={10} max={100} 
                  min={0}
                  onChange={onChangeIp2}
                  defaultValue={dataHilo?.value2}
                />
              </div>
            </div>
            :
            <InputNumber
              style={{ width: '90%' }}
              // addonAfter="%" 
              // min={10} max={100} 
              min={0}
              onChange={onChangeIp}
              defaultValue={Number(defaultValue)}
            />
          }

        </div>
        {
          hasRight([RIGHTS.EditSettingOriginals]) ? (
            <div className="col-3 text-left">
              <Button
                type="primary"
                onClick={onUpdate}
              >
                {TEXT_DEF.text0016}
              </Button>
            </div>
          ) : null
        }
      </div>
    )
  }

  return (
    <>
    { data ? 
      <div className="col-12 flex-col"
        style={{ display: "flex", marginTop: 30, alignItems: "center" }}
      >
        <div className="col-12 flex">
          <div className="col-3 text-left">{TEXT_DEF.text0065}</div>
          <div className="col-6">
            <Switch
              checkedChildren="Bật"
              unCheckedChildren="Tắt"
              defaultChecked={data?.find(x => x.key == "isMaintenance")?.value === "true" ? true : false}
              style={{ width: 60, marginTop: "5px" }}
              onChange={(e) => handleChangeSwitch(e)}
            />
          </div>
          {
            hasRight([RIGHTS.EditSettingOriginals]) ? (
              <div className="col-3 text-left">
                <Button
                  type="primary"
                  onClick={() => showConfirm("isMaintenance")}
                >
                  {TEXT_DEF.text0016}
                </Button>
              </div>
            ) : null
          }
        </div>
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Số lượng mìn mặc định", data?.find(x => x.key == "num_of_mine_df")?.value, (e) => setValueIp32(e), () => showConfirm("num_of_mine_df"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Thua lần đầu", data?.find(x => x.key == "firstLose")?.value, (e) => setValueIp1(e), () => showConfirm("firstLose"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Tỉ lệ thắng", data?.find(x => x.key == "ratesWin")?.value, (e) => setValueIp2(e), () => showConfirm("ratesWin"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Tỉ lệ rev", data?.find(x => x.key == "ratioRev")?.value, (e) => setValueIp3(e), () => showConfirm("ratioRev"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Tỉ lệ bonus", data?.find(x => x.key == "ratioBonus")?.value, (e) => setValueIp4(e), () => showConfirm("ratioBonus"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Fees", data?.find(x => x.key == "fees")?.value, (e) => setValueIp5(e), () => showConfirm("fees"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Bias", data?.find(x => x.key == "bias")?.value, (e) => setValueIp6(e), () => showConfirm("bias"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Tỉ lệ theo độ khó", data?.find(x => x.key == "ratio_per_difficult")?.value, (e) => setValueIp7(e), () => showConfirm("ratio_per_difficult"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("1_mines", data?.find(x => x.key == "1_mines")?.value, (e) => setValueIp8(e), () => showConfirm("1_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("2_mines", data?.find(x => x.key == "2_mines")?.value, (e) => setValueIp9(e), () => showConfirm("2_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("3_mines", data?.find(x => x.key == "3_mines")?.value, (e) => setValueIp10(e), () => showConfirm("3_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("4_mines", data?.find(x => x.key == "4_mines")?.value, (e) => setValueIp11(e), () => showConfirm("4_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("5_mines", data?.find(x => x.key == "5_mines")?.value, (e) => setValueIp12(e), () => showConfirm("5_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("6_mines", data?.find(x => x.key == "6_mines")?.value, (e) => setValueIp13(e), () => showConfirm("6_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("7_mines", data?.find(x => x.key == "7_mines")?.value, (e) => setValueIp14(e), () => showConfirm("7_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("8_mines", data?.find(x => x.key == "8_mines")?.value, (e) => setValueIp15(e), () => showConfirm("8_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("9_mines", data?.find(x => x.key == "9_mines")?.value, (e) => setValueIp16(e), () => showConfirm("9_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("10_mines", data?.find(x => x.key == "10_mines")?.value, (e) => setValueIp17(e), () => showConfirm("10_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("11_mines", data?.find(x => x.key == "11_mines")?.value, (e) => setValueIp18(e), () => showConfirm("11_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("12_mines", data?.find(x => x.key == "12_mines")?.value, (e) => setValueIp19(e), () => showConfirm("12_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("13_mines", data?.find(x => x.key == "13_mines")?.value, (e) => setValueIp20(e), () => showConfirm("13_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("14_mines", data?.find(x => x.key == "14_mines")?.value, (e) => setValueIp21(e), () => showConfirm("14_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("15_mines", data?.find(x => x.key == "15_mines")?.value, (e) => setValueIp22(e), () => showConfirm("15_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("16_mines", data?.find(x => x.key == "16_mines")?.value, (e) => setValueIp23(e), () => showConfirm("16_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("17_mines", data?.find(x => x.key == "17_mines")?.value, (e) => setValueIp24(e), () => showConfirm("17_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("18_mines", data?.find(x => x.key == "18_mines")?.value, (e) => setValueIp25(e), () => showConfirm("18_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("19_mines", data?.find(x => x.key == "19_mines")?.value, (e) => setValueIp26(e), () => showConfirm("19_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("20_mines", data?.find(x => x.key == "20_mines")?.value, (e) => setValueIp27(e), () => showConfirm("20_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("21_mines", data?.find(x => x.key == "21_mines")?.value, (e) => setValueIp28(e), () => showConfirm("21_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("22_mines", data?.find(x => x.key == "22_mines")?.value, (e) => setValueIp29(e), () => showConfirm("22_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("23_mines", data?.find(x => x.key == "23_mines")?.value, (e) => setValueIp30(e), () => showConfirm("23_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("24_mines", data?.find(x => x.key == "24_mines")?.value, (e) => setValueIp31(e), () => showConfirm("24_mines"), false)}
        <div style={{ margin: "10px 0px" }} />
      </div> : null }
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text0071}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0072}
          actionNext={onRequest}
          close={() => { setIsShowModalConfirm(false); setDataUp(undefined) }}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text0073}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </>
  )
}

export default MinesSetting;