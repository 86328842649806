import React, { useEffect, useState } from 'react';
import API, { CLASSIC_AWARD } from '../../network/api';
import { Form, Input, Button, DatePicker, Table, Spin, Pagination, Select } from 'antd';
import * as _ from 'lodash';
import { TEXT_DEF } from '../../constant/text';
import moment from 'moment';
import 'moment-timezone';
import dayjs from 'dayjs';
import { disabledTimeTo, disabledTimeFrom, validateDateRange } from '../../utils/dateUtils';
import { typeGame, dataProvinces } from '../../constant/province';

const ClassicAward = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [classicLotteryList, setClassicLotteryList] = useState([]);
  const [params, setParams] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [modeSl, setModeSl] = useState();
  const dataMode = [
    {
      key: 0,
      value: false,
      label: 'Cho HV thật',
    },
    {
      key: 1,
      value: true,
      label: 'Cho HV giả',
    },
  ];
  const locale = {
    emptyText: TEXT_DEF.text0043,
  };
  const dollarUS = Intl.NumberFormat('en-US');
  const dataProvince = dataProvinces[0];
	const areas = region ? dataProvince[region] : dataProvince.all;

  useEffect(() => {
    getClassicLotteryData();
  }, [page, params]);

  const getClassicLotteryData = async () => {
    if (!params) {
      return;
    }

    try {
      setLoading(true);

      const result = await API.get(`${CLASSIC_AWARD}?take=10&skip=${page}&order=DESC&${params}`);
      const classicLottery = _.get(result, 'data.data[0]', []);
      const totalRecord = _.get(result, 'data.data[1]', []);
      const list = [];

      for (const item of classicLottery) {
        if (item?.status === 'not_paid') {
          continue;
        }
        const awardDetail = JSON.parse(item?.awardDetail);
        const dataTypeGame = typeGame[item?.type];

        list.push({
          type: dataTypeGame,
          turnIndex: item?.turnIndex,
          totalRevenue: item.totalRevenue,
          totalPayout: dollarUS.format(Number(item.totalPayout)),
          totalProfit: item.totalProfit,
          bonusPrice: dollarUS.format(Number(item.bonusPrice)),
          awardDetail: `
                    Giải đặc biệt: ${awardDetail[0][0]},
                    Giải nhất: ${awardDetail[1][0]},
                    Giải nhì: ${awardDetail[2][0]} - ${awardDetail[2][1]},
                    Giải ba: ${awardDetail[3][0]} - ${awardDetail[3][1]} - ${awardDetail[3][2]} - ${awardDetail[3][3]} - ${awardDetail[3][4]} - ${awardDetail[3][5]},
                    Giải tư: ${awardDetail[4][0]} - ${awardDetail[4][1]} - ${awardDetail[4][2]} - ${awardDetail[4][3]},
                    Giải năm: ${awardDetail[5][0]} - ${awardDetail[5][1]} - ${awardDetail[5][2]} - ${awardDetail[5][3]} - ${awardDetail[5][4]} - ${awardDetail[5][5]},
                    Giải sáu: ${awardDetail[6][0]} - ${awardDetail[6][1]} - ${awardDetail[6][2]},
                    Giải bảy: ${awardDetail[7][0]} - ${awardDetail[7][1]} - ${awardDetail[7][2]} - ${awardDetail[7][3]},
                `,
        });
      }

      setLoading(false);
      setClassicLotteryList(list);
      setTotalRecord(totalRecord);
    } catch (error) {
      console.log('Error: ', error);
      setLoading(false);
    }
  };

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const onChangePage = (page) => {
    setPage(page);
  };

  const orderColums = [
    {
      title: TEXT_DEF.text0079,
      width: 100,
      fixed: 'left',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: TEXT_DEF.text0082,
      width: 150,
      fixed: 'left',
      dataIndex: 'turnIndex',
      key: 'turnIndex',
    },
    {
      title: 'Giải thưởng',
      width: 300,
      fixed: 'left',
      dataIndex: 'awardDetail',
      key: 'awardDetail',
      render(text, record) {
        return {
          props: {
            style: { 'white-space': 'pre-line' },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: 'Tiền bonus',
      width: 100,
      fixed: 'left',
      dataIndex: 'bonusPrice',
      key: 'bonusPrice',
    },
    {
      title: 'Tiền cược',
      width: 150,
      fixed: 'left',
      dataIndex: 'totalRevenue',
      key: 'totalRevenue',
    },
    {
      title: 'Tiền thanh toán',
      width: 150,
      fixed: 'left',
      dataIndex: 'totalPayout',
      key: 'totalPayout',
    },
    {
      title: 'Lợi nhuận',
      width: 150,
      fixed: 'left',
      dataIndex: 'totalProfit',
      key: 'totalProfit',
    },
  ];

  const getParams = () => {
    let params = '';

    if (region) {
      const gameSl = form.getFieldValue('gameFilter').trim();
      params += `&type=${gameSl}`;
    }

    if (province) {
      params += `&province=${province}`;
    }

    if (form.getFieldValue('turnIndex')) {
      const turnIndex = form.getFieldValue('turnIndex').trim();
      params += `&turnIndex=${turnIndex}`;
    }

    if (dateFrom) {
      params += `&startDate=${moment(dateFrom).unix()}`;
    }

    if (dateTo) {
      params += `&endDate=${moment(dateTo).unix()}`;
    }

    params += `&isTestPlayer=${modeSl}`;

    setParams(params);
  };

  const onSearch = () => {
    getParams();
    setPage(1);
  };

  const handleSelectGameType = (value) => {
    setRegion(value);
  };

  const filterOptionMode = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === 'from') setDateFrom(moment(e.$d).format('yyyy-MM-DD HH:mm:ss'));
      else setDateTo(moment(e.$d).format('yyyy-MM-DD HH:mm:ss'));
    } else {
      if (type === 'from') setDateFrom(null);
      else setDateTo(null);
    }
  };

  const disabledDateFrom = (current) => {
    if (!dateTo) {
      return current && current > dayjs().endOf('day');
    }
    return current && (current > dayjs().endOf('day') || current < dayjs(dateTo).subtract(3, 'months').startOf('day') || current > dayjs(dateTo).endOf('day'));
  };

  const disabledDateTo = (current) => {
    if (!dateFrom) {
      return current && current > dayjs().endOf('day');
    }
    return current && (current > dayjs().endOf('day') || current < dayjs(dateFrom).startOf('day') || current > dayjs(dateFrom).add(3, 'months').endOf('day') || current > dayjs(dateFrom).add(3, 'months').endOf('day'));
  };

  const renderTable = () => {
    return (
      <>
        <div className="col-12">
          <Form form={form} layout="inline" autoComplete="off" onFinish={() => onSearch()}>
            <div className="col-12" style={{ marginBottom: 20, display: 'flex' }}>
              <div className="col-4">
                <Form.Item name="gameFilter" label={TEXT_DEF.text0051} rules={[{ required: true, message: TEXT_DEF.text0004 }]}>
                  <Select placeholder="Chọn trò chơi" value={region} onChange={(value) => handleSelectGameType(value)} style={{ width: '100%' }}>
                    {Object.entries(typeGame).map(([key, value]) => (
                      <options key={key} value={key}>
                        {value}
                      </options>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="provinceFilter" label={TEXT_DEF.text0198}>
                  <Select placeholder="Chọn tình/thành phố" value={province} onChange={(value) => setProvince(value)} style={{ width: '100%' }}>
                    {Object.entries(areas).map(([key, value]) => (
                      <options key={key} value={key}>
                        {value}
                      </options>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="modeFilter" label={'Người chơi'} rules={[{ required: true, message: TEXT_DEF.text0004 }]}>
                  <Select showSearch placeholder="Chọn dữ liệu" optionFilterProp="children" onChange={(val) => setModeSl(val)} filterOption={filterOptionMode} options={dataMode} allowClear />
                </Form.Item>
              </div>
            </div>
            <div className="col-12" style={{ marginBottom: 15, display: 'flex' }}>
              <div className="col-4">
                <Form.Item
                  name="dateFrom"
                  label={TEXT_DEF.text0024}
                  rules={[
                    {
                      required: true,
                      message: TEXT_DEF.text0004,
                    },
                    {
                      validator: () => {
                        return validateDateRange(dateFrom, dateTo);
                      },
                    },
                  ]}
                >
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, 'from')}
                    format={'DD-MM-YYYY HH:mm:ss'}
                    showTime={{ format: 'HH:mm:ss' }}
                    style={{ width: '100%' }}
                    disabledDate={disabledDateFrom}
                    disabledTime={disabledTimeFrom(dateTo)}
                    // inputReadOnly={true}
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item
                  name="dateTo"
                  label={TEXT_DEF.text0025}
                  rules={[
                    {
                      required: true,
                      message: TEXT_DEF.text0004,
                    },
                  ]}
                >
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, 'to')}
                    format={'DD-MM-YYYY HH:mm:ss'}
                    showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={disabledDateTo}
                    disabledTime={disabledTimeTo(dateFrom)}
                    // inputReadOnly={true}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-12">
              <div className="col-4">
                <Form.Item name="turnIndex" label={'Lượt xổ'}>
                  <Input style={{ width: '100%' }} />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0026}
              </Button>
            </div>
          </Form>

          <Spin spinning={loading} delay={500} size="large">
            <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
              <Table
                key="lotteryAwardList"
                locale={locale}
                columns={orderColums}
                dataSource={classicLotteryList}
                pagination={false}
                bordered
                style={{ width: '100%' }}
                scroll={{
                  x: 1200,
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                marginTop: 15,
                justifyContent: 'flex-end',
              }}
            >
              <Pagination current={page} total={totalRecord} onChange={onChangePage} defaultPageSize={10} showSizeChanger={false} showTotal={showTotal} />
            </div>
          </Spin>
        </div>
      </>
    );
  };

  return <div className="col-12">{renderTable()}</div>;
};

export default ClassicAward;
